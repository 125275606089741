import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src="acadia-national-park.png" className="App-logo" alt="logo" /> */}
        <p>
          Kenny <span className="heart">♥️</span> Val
        </p>
      </header>
    </div>
  );
}

export default App;
